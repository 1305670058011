#kpi_box {
    width: 100%;
    overflow-y: scroll;
    display: flex;
}

.kpi {
    flex-grow: 1;
    flex: 1 1;
    width: auto;
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
    border-bottom: 4px solid #b4b4b4;
}

.kpi > .kpi_title {
    text-align: center;
    height: 47px;
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    line-height: 17px;
}

.kpi > .kpi_value {
    text-align: center;
    font-size: 30px;
    color: #fff;
    line-height: 25px;
    font-weight: 700;
}