.App-header {
  text-align: left;
  position: relative;
  margin: 0;
  background-color: #2a2c47;
  --header-height: 70px;
  height: var(--header-height);
  color: #e6e6e6;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-shadow: 0 2px 3px rgba(0,0,0,.16);
}

.App-header > h1 {
  display: inline;
  font-weight: 300;
  margin: 0 0 0 40px;
  line-height: var(--header-height);
}

#Modules {
  padding-top: 70px;
  color: #b4b4b4;
  text-align: left;
  padding-left: 1.5vw;
  padding-right: 1.5vw;
}

.Modules > p {
  text-align: left;
  margin-left: 20px;
  font-weight: 500;
  color: #b4b4b4;
}

.card {
  margin: 1%;
  border-radius: 4px;
  box-shadow: 0 3px 6px rgba(0,0,0,.16), 0 3px 6px rgba(0,0,0,.23);
  background-color: #2a2c47;
  position: relative;
}

.App-header > #date_pickers > div > div > .date_picker {
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  font-size: 20px;
  width: 130px;
  background: transparent;
  border: none;
  border-radius: 2px;
  border-bottom: 2px solid rgb(230, 230, 230);
  color: rgb(230, 230, 230);
}

#date_pickers {
  display:inline-block;
  position: absolute;
  right: 100px;
  top: 25px;
}

header > .loading_gif {
  width: 40px;
  position: absolute;
  right: 20px;
  top: 20px;
  transform: translate(-50%, - 50%);
}