.review {
    flex-grow: 1;
    flex: 1 0 21%;
    width: auto;
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
    border-bottom: 4px solid #b4b4b4;
}

.review > .content {
    color: white;
    font-size: 18px;
}

#review_box {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}